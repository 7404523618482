import { batch } from "solid-js";
import { reconcile } from "solid-js/store";

import CreditLine from "@repo/models-kikoff/lending/CreditLine";
import ManualIdvAttempt, {
  ManualIdvAttemptCanada,
} from "@repo/models-kikoff/ManualIdvAttempt";
import PaymentMethod from "@repo/models-kikoff/payments/PaymentMethod";
import User, { UserCanada } from "@repo/models-kikoff/User";
import canadaAdminRpc from "@repo/protobuf/api/canadaAdminRpc";
import {
  UsersSearchRequest,
  UsersSubmitManualRiskStatusRequest,
} from "@repo/protobuf/gen/kikoff_canada/protobuf/services/asgard/users_service_pb";
import { MessageFrom } from "@repo/protobuf/utils";
import PostalCode from "@repo/utils/PostalCode";
import UArray from "@repo/utils/UArray";
import UObject from "@repo/utils/UObject";
import FeatureStore from "@repo/utils-solid/FeatureStore";

import {
  ExtendedCreditLine,
  ExtendedCreditLineCanada,
  useCreditLines,
} from "./creditLines";
import { usePayments } from "./payments";

export type UserDump = {
  dob: string;
  creditLines: CreditLine.Id[];
  rawIdvPayloads: Record<any, any>[];
};
export namespace UserDump {
  export type Resolved = UObject.Override<
    UserDump,
    { creditLines: ExtendedCreditLine[] }
  >;
}

export namespace UserDump {
  export namespace Query {
    export function parse(query: string): MessageFrom<UsersSearchRequest> {
      if (query.startsWith("U-"))
        return { userId: `U-${query.slice(2).replaceAll("-", "")}` };
      if (query.startsWith("CL-")) return { creditLineId: query };
      if (query.includes("@")) return { email: query };

      if (PostalCode.validate(query)) return { postalCode: query };

      const maybePhone = query.replace(/[+()/\- ]/g, "");
      if (/^\d+$/.test(maybePhone)) {
        if (maybePhone.length === 11) return { phone: maybePhone };
        if (maybePhone.length === 10) return { phone: `1${maybePhone}` };
      }

      const words = query.split(" ");
      if (UArray.hasLengthOf(words, 2) && words[1])
        return { firstName: words[0], lastName: words[1] };

      throw new Error("Unable to parse user query.");
    }
  }
}

const initialState = {
  byId: {} as Record<User.Id, User>,
  dumpById: {} as Record<User.Id, UserDump>,
  usersByQuery: {} as Record<string, User.Id[]>,
  paymentMethodsById: {} as Record<User.Id, PaymentMethod.Id[]>,
  manualIdvAttemptsById: {} as Record<User.Id, ManualIdvAttempt>,
  pendingManualIdvAttempts: null as User.Id[] | null,
};

export const { Provider, useUsers } = FeatureStore.init(
  "Users",
  initialState,
  ([store, setStore], { attachAction, withLogger }) => {
    const [creditLines, setCreditLines] = useCreditLines();
    const [payments, setPayments] = usePayments();

    const accessors = {
      query: attachAction(
        (query: string) =>
          store.usersByQuery[query]?.map((id) => store.byId[id]!),
        (query) => actions.query(query),
      ),
      dumpFor: attachAction(
        (id: User.Id) =>
          store.byId[id] && {
            ...store.byId[id],
            ...store.dumpById[id],
            creditLines: store.dumpById[id]?.creditLines.map(
              (id) => creditLines.byId(id)!,
            ),
          },
        (id) => actions.show(id),
        (id) => () => store.dumpById[id],
      ),
      paymentMethods: attachAction(
        (id: User.Id) =>
          store.paymentMethodsById[id]?.map((paymentMethodId) =>
            payments.paymentMethod(paymentMethodId),
          ),
        (id: User.Id) => actions.paymentMethods(id),
      ),
      pendingManualIdvAttempts: attachAction(
        () =>
          store.pendingManualIdvAttempts?.map(
            (id) => store.manualIdvAttemptsById[id]!,
          ),
        () => actions.fetchPendingManualIdvAttempts(),
        () => () => store.pendingManualIdvAttempts,
      ),
    };

    const mutations = {
      one(user: User) {
        setStore("byId", user.id, reconcile(user));
      },
      many(users: User[]) {
        batch(() => {
          for (const user of users) {
            mutations.one(user);
          }
        });
      },
      oneManualIdvAttempt(manualIdvAttempt: ManualIdvAttempt) {
        setStore(
          "manualIdvAttemptsById",
          manualIdvAttempt.userId,
          reconcile(manualIdvAttempt),
        );
      },
      manyManualIdvAttempts(manualIdvAttempts: ManualIdvAttempt[]) {
        batch(() => {
          for (const manualIdvAttempt of manualIdvAttempts) {
            mutations.oneManualIdvAttempt(manualIdvAttempt);
          }
        });
      },
      pendingManualIdvAttempts(manualIdvAttempts: ManualIdvAttempt[]) {
        batch(() => {
          mutations.manyManualIdvAttempts(manualIdvAttempts);
          setStore(
            "pendingManualIdvAttempts",
            manualIdvAttempts.map(({ userId }) => userId),
          );
        });
      },
    };

    const usa = {
      actions: {} as never,
    };
    const canada = {
      actions: {
        async query(query: string) {
          if (!query) return;
          try {
            const { users } = await canadaAdminRpc.Users.search(
              UserDump.Query.parse(query),
            );

            mutations.many(users.map(UserCanada.normalize));
            setStore(
              "usersByQuery",
              query,
              users.map(({ id }) => id as User.Id),
            );
          } catch (_) {
            setStore("usersByQuery", query, []);
          }
        },
        show(userId: User.Id) {
          return canadaAdminRpc.Users.show({ userId }).then(
            ({ userDetails, creditLineDetails, rawIdvPayloads }) => {
              batch(() => {
                mutations.one(UserCanada.normalize(userDetails!.user!));
                setCreditLines.many(
                  creditLineDetails.map(ExtendedCreditLineCanada.normalize),
                );

                setStore("dumpById", userDetails!.user!.id, {
                  dob: userDetails!.dob,
                  creditLines: creditLineDetails.map(
                    ({ creditLine }) => creditLine!.id,
                  ),
                  rawIdvPayloads: rawIdvPayloads.map((payload) =>
                    JSON.parse(payload),
                  ),
                });
              });
            },
          );
        },
        deactivate(userId: User.Id) {
          return canadaAdminRpc.Users.deactivate({ userId }).then(() =>
            actions.show(userId),
          );
        },
        activate(userId: User.Id) {
          return canadaAdminRpc.Users.activate({ userId }).then(() =>
            actions.show(userId),
          );
        },
        allowIdvRetry(userId: User.Id) {
          return canadaAdminRpc.Users.allowIdvRetry({ userId }).then(() =>
            actions.show(userId),
          );
        },
        paymentMethods(userId: User.Id) {
          // TODO: Need listPaymentMethods endpoint
          batch(() => {
            setPayments.manyPaymentMethods([]);
            setStore("paymentMethodsById", userId, []);
          });
        },
        fetchPendingManualIdvAttempts() {
          return canadaAdminRpc.Users.listPendingManualIdvAttempts({}).then(
            ({ manualIdvAttempts }) => {
              mutations.pendingManualIdvAttempts(
                manualIdvAttempts.map(ManualIdvAttemptCanada.normalize),
              );
            },
          );
        },
        reviewManualIdvAttempt(
          userId: User.Id,
          status: keyof typeof UsersSubmitManualRiskStatusRequest.RiskStatus,
          failureReason?: string,
        ) {
          return canadaAdminRpc.Users.submitManualRiskStatus({
            userId,
            riskStatus: UsersSubmitManualRiskStatusRequest.RiskStatus[status],
            failureReason,
          }).then(({ manualIdvAttempts }) => {
            const prev = store.pendingManualIdvAttempts;
            mutations.pendingManualIdvAttempts(
              manualIdvAttempts.map(ManualIdvAttemptCanada.normalize),
            );
            const set = new Set(store.pendingManualIdvAttempts);
            return {
              next:
                prev?.find((id) => set.has(id)) ||
                store.pendingManualIdvAttempts![0],
            };
          });
        },
      },
    };

    const actions = withLogger(
      "action",
      import.meta.env.API_ADAPTER === "canada" ? canada.actions : usa.actions,
    );

    return { accessors, actions };
  },
);
